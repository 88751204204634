/* AddEventForm.css */
.add-event-form-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
}
/* App.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  z-index: 1000; /* Valor alto para asegurar que esté por encima de otros elementos */
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}



.add-event-form-popup form {
  display: flex;
  flex-direction: column;
}







/* AddEventForm.css */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; 
}
