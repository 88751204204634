/* RectangleComponent.css */
.neweventsV2 {
  width: 600px;
  height: 150px;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.newevents {
  width: 600px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}
.newevents span {
  color: white;
  font-weight: bold;
}
.newevents-list-container {
  height: 50vh;/* ajusta el tamaño según necesites */
  overflow-y: auto;
  width: 40vw;
}
.newevents-list {
  display: flex;
  flex-direction: column;
}
.newevents-listV2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
}
/* EventRectangle.css */
.event-rectangle {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 350px;
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.event-name {
  font-size: 18px;
  font-weight: bold;
}

.event-date {
  font-size: 14px;
  color: #fff;
  background-color: #3498db;
  padding: 5px 10px;
  border-radius: 5px;
}

.event-detail {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.detail-item {
  margin-bottom: 5px;
  font-size: 14px;
}

.detail-item strong {
  margin-right: 5px;
}
