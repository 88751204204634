/* RectangleComponent.css */
.oldevents {
  width: 27vw;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.oldevents span {
  color: white;
  font-weight: bold;
}
.oldevents-list-container {
  height: 55%; /* ajusta el tamaño según necesites */
  overflow-y: auto;
  padding: 10;
  width: 95%;
}
.oldevents-list {
  display: flex;
  flex-direction: column;
  width: 26vh;
}
/* EventRectangle.css */

.oldevent-rectanglev2 {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  height: 80px;
  width: 24vw;
  display: flex;
}
.oldevent-rectanglev2-date {
  background-color: #003865;
  border: 0px solid #ddd;
  border-radius: 10px 0% 0% 10%;
  height: 80px;
  width: 8vw;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.oldevent-rectanglev2-detail {
  background-color: #f9f9f9;
  border: 0px solid #ddd;
  border-radius: 0px 10% 10% 0%;
  height: 80px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #302c2c;
}
.oldeventv2-header {
  
  justify-content: space-between;
  align-items: start;
  margin-bottom: 0px;
  height: 20px;
  width: 100%;
  max-height: 20px;
  padding: 2px 2px 2px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap ;
  font-weight: 700;
  text-align: start;

}
.oldeventv2-detail {
  margin-bottom: 0px;
  height: 20px;
  padding: 2px 2px 2px 10px;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap ;
  flex-direction: row;
  justify-content: space-between;
  
}
.oldeventv2-pending {
  margin-bottom: 0px;
  height: 20px;
  padding: 0px 5px 2px 5px;
  width: 30;
  background-color: orange;
  border-radius: 10px;
  margin-right: 10px;
  color: white;
  font-size: 14px;
  
}

.oldevent-rectangle {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;

  width: 350px;
}
.oldevent-rectangle-pending {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin-bottom: 5px;
  align-items: center;
  display: "flex";
  flex-direction: column;
  align-self: flex-end;
  width: 120px;
}

.oldevent-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.oldevent-name {
  font-size: 18px;
  font-weight: bold;
}

.oldevent-date {
  font-size: 14px;
  color: #fff;
  background-color: #0057b8;
  padding: 5px 10px;
  border-radius: 5px;
}

.oldevent-detail {
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.olddetail-item {
  margin-bottom: 5px;
  font-size: 14px;
  align-items: stretch;
}
.olddetail-item-pending {
  margin-bottom: 5px;
  font-size: 14px;
  align-items: center;
  flex-direction: column;
}

.olddetail-item strong {
  margin-right: 5px;
}
