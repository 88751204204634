/* Sidebar.css */
.sidebar {
  width: 100px;
  height: 100%;
  background-color: #003865;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  /* Centra los elementos verticalmente */
  align-items: center; /* Centra los elementos horizontalmente */
}

.sidebar.expanded {
  width: 150px;
}

.toggle-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  margin: 10px;
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
}

.sidebar-list li {
  margin-bottom: 10px;
}

.sidebar-list li a {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column; /* Icono arriba del nombre */
  align-items: center;
}

.sidebar-list li a:hover {
  color: #ccc;
}

.sidebar-list li a svg {
  margin-bottom: 5px; /* Espacio entre el icono y el nombre */
  font-size: 1.5rem;
}
.sidebar-top {
  padding: 10px;
  height: 30%;
}

.sidebar-mid {
  padding: 10px;
  height: 60%;
  flex-direction: column;
}

.sidebar-bot {
  flex-grow: 1;
  height: 15%;
}
